body {
  height: 100%;
}

// Container

.container-fluid {
 padding-left: 0;
  height: 100%;
}

// Columns

div[class^='col-md']{
  padding-left: 10px;
  padding-right: 10px;

  &:last-child {
    padding-right: 20px;
  }

  &.panel-team-pending-member{
    padding-right: 0;
  }
  &.panel-team-active-member, &.panel-team-inactive-member, &.panel-team-invitations{
    padding-right: 0;
    margin-top: 10px;
    margin-bottom: 20px;

    .panel{
      border: 1px solid #dff0d8;
      box-shadow: none;
    }
    .user-info{
        padding: 10px 0px;
    }
    .company-info, .invitation-info, .projects-info{
        font-size: 12px;
    }
  }
}

// NavBar

.with-navbar{
  padding-top: 70px;
}

.navbar {
  background-color: white;
  border-bottom: 1px solid darkgray;
  .btn {
    height: 20px;
    margin-top: 20px;
  }
}

.navbar-header {
  width: 16.66%;
}


.navbar-brand {
  width: 100%;
}


.navbar-right {
  line-height: 50px;
  padding-right: 20px;
}

.username {
  color: black;
  font-weight: bold;
  padding: 0 20px;
  display: block;
}



// At a glance section

.at-glance {

  h1 {
    float: left;
  }

  span {
    float: right;
    position: absolute;
    bottom: 25%;
    right: 20%;
  }

  .border-left{
    border-left: 1px solid #d3e0e9 ;
  }

  .panel-body{
    min-height: 160px;
  }

}


small {
  color: darkgray;
}
.container-fluid, .modal-form {
  .field {

    .control-label {
      color: @input-control-label;
      font-weight: bolder;
      padding-bottom: 5px;
    }

  }

  .form-control {
    background-color: @input-background-color;
    color: @input-placeholder-color;
  }

  p {
    color: @input-control-label;
  }

  .panel-settings {
    margin-top: 20px;
    min-height: 500px;
    font-size: @ca-default-font-size;

    .billing-title {
      color: black;
      font-weight: bold;
      padding: 0 20px;
      display: block;
      float: left;
    }

    .coupon-panel{
      .coupon-label{
        text-align: center;
        width: 100%;
        cursor: pointer;
      }
      .panel{
        border: none;
        margin-bottom: 0;
      }
    }
  }

  .form-button {
    width: 100%;
  }

  .panel-default > .panel-heading {
    background-color: #FFFFFF !important;
    border: none !important;
    font-weight: 800;

    span {
      color: #636b6f;
      font-size: 12px;
      font-weight: 300;
      margin-left: 10px;
    }

  }

  .panel-users > .panel-heading {
    color: #000000;
    font-weight: 800;

    span {
      color: #636b6f;
      font-size: 12px;
      font-weight: 300;
      margin-left: 10px;
    }

  }

  .modal-label{
    color: @ca-blue !important;
    font-weight: bolder;
  }

}
  .user-avatar{
    height: 60px;
    margin-top: 10px;
    width: 60px;
  }
  .settings-avatar{
    height: 80px;
    margin-top: 0;
    width: 80px;
  }

  .user-info{
    display: block;
    padding: 0 20px;
    word-wrap: break-word;
  }

  .user-company{
    color: #000000;
  }

  .user-projects, .user-link{
    font-size: 12px;
  }

  .user-pending{
    color: #F6F25E;
  }

  .user-active, .project-active{
    color: #3097D1;
  }

  .project-closed , .project-archived {
    color: #bd4444;
  }

  .user-project-image{
    height: 80px;
    width: 80px;
  }

  .user-project-image-panel{
    float: left;
    height: auto;
    margin-right: 10px;
    width: auto;

    &.view-more-panel{
      background-color: #3097D1;
      color: white;
      height: 68px;
      width: 68px;
      padding: 25px 0;
      text-align: center;
      border-radius: 6px;

      .view-more{
        margin: auto;
        width: 20px;
        height: 20px;
      }

    }

  }

  .user-project-panel{
    height: 80px;
    margin-top: 10px;
    width: 100%;
    float: left;
  }

  .payment-history-headers{
    color: #3097D1;
    font-size: 12px;
    font-weight: bolder;
    line-height: 26px;

    .table-header{
      border-bottom: 1px solid #D3E0E9;
      border-right: 1px solid #D3E0E9;

      &.last{
        border-right: none;
      }

    }

  }

.payment-history-body{
  color: #9CA4A8;
  font-weight: bolder;
  line-height: 26px;

  .table-content{
    border-bottom: 1px solid #D3E0E9;
    border-right: 1px solid #D3E0E9;
    font-size: 12px;

    &.last{
      border-right: none;
    }

  }

}


.guests-wrapper {
   background-color: #1497EF;
   font-family: 'Roboto', sans-serif;

   display: flex;
   flex-direction: column;
   min-height: 100vh;
   justify-content: center;
   align-items: center;


   .logo {
     max-height: 100px;
     display: block;
     margin-bottom: 50px;
   }

  .form-control:focus{ box-shadow: none!important; }

  ::-webkit-input-placeholder {
    color: #ddd;
    font-weight: normal;
  }

  :-moz-placeholder { /* Firefox 18- */
    font-weight: normal;
    color: #ddd;
  }

  ::-moz-placeholder {  /* Firefox 19+ */
    font-weight: normal;
    color: #ddd;
  }

  :-ms-input-placeholder {
    font-weight: normal;
    color: #ddd;
  }


  #registration-form, #login-form{
    margin: auto;
    align-self: center;


    legend {
      color: #fff;
      text-decoration: none;
      border: none;
      text-align: center;
      font-size: 48px;
      font-weight: 800;
    }

    .last-name {
      right: 20px;
      position: absolute;
    }

    .domain-suffix {
      padding-top: 24px;
      display: block;
      font-weight: 400;
      font-size: 18px;
    }

    .choose-profile-image {
      img {
        width: 40px;
      }

      text-align: center;
      margin-bottom: 40px!important;

      .vicp-operate a{
        background-color:#3097D1;
        &:hover{
          text-decoration: none;
        }
      }
    }
    .profile-preview {
      img {
        width: 100px !important;
        border-radius: 50%;
      }
      text-align: center;
      margin-bottom: 40px!important;
    }

    .next-btn {
      width: 120px;
      border-radius: 4px;
      font-weight: 600;
      background-color: transparent;
      border: 2px solid #fff;
      margin: auto;
      height: 36px;

      &:hover {
        background-color: #fff;
        color: #37A7F2;
      }

    }

  }


  .guest-footer {
    width: 100%;
    padding: 10px;
    background-color: #37A7F2;
    text-align: center;

    p {
      color: #fff;
      padding: 0;
      margin: 0;
      font-weight: 500;
      a {
        color: #fff;
        font-weight: 900;
      }
    }
  }

}


.logos-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-logo{
  max-height: 100px;
}

.modal{

  .modal-header{
    background-color: @ca-blue;
    color: #FFFFFF;
    text-align: center;
  }

  .modal-footer{
    border-top: none !important;
  }

  .modal-check-in-out{
    font-size: 18px;
    font-weight: bolder;
    text-align: center;

    .message{
      color: #636b6f !important;
      width: 180px;
      margin: 20px auto;
    }

    .message-label{
      color: #3097D1 !important;
      width: 250px;
      margin: 20px auto;
      font-size: 14px;
    }

    .btn{
      margin: 0 10px;
    }

    #radio-lunch{

      margin-bottom: 10px;

      label{
        font-weight: normal;
      }

      input[type="radio"]{
        margin: 0 10px;
      }

      input[type="radio"]:checked{
        background-color: #3097D1;
        + label{
          font-weight: bolder;
        }
      }
    }

    #radio-incidents{

      margin-bottom: 10px;

      label{
        color: #636b6f;
        width: 200px;
        font-weight: normal;
        display: block;
        margin: 10px auto;
        border: 1px solid #e4ecf2;
        background-color: #fdfdfe;
      }

      .active{
        background-color: #3097D1;
        color: #FFFFFF;
      }

      input[type="radio"]{
        display: none;
        margin: 0 10px;
      }

      input[type="radio"]:checked{
        + label{
          color: #FFFFFF;
          background-color: #3097D1;
          font-weight: bolder;
        }
      }
    }

    #textarea-incident{
      border: 1px solid #e4ecf2;
      background-color: #fdfdfe;
    }

  }

}

.team-member-image{
  border: 1px solid #d3e0e9;
  border-radius: 50%;
  height: 30px;
  padding: 2px;
  width: 30px;
}

.table{

  font-size: 12px;

  .table-header{
    color: #3097D1;
    font-weight: bolder;
    line-height: 26px;
  }

}

.remove-member{
  color: red;
  font-size: 14px;
}

.btn-invite{
  background-color: #2E3747;
  color: #ffffff;
}

.project-container {
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  height: 250px;
  border-bottom: 1px solid #e4ecf2;

  .clickPin {
    width: 200px;
    height: 200px;
    border-radius: 100px;
    position: absolute;
    top: 10%;
    left: 40%;
    cursor: pointer;
    z-index: 10;

    &:hover {
      background-color: rgba(255,0,0,0.25);
    }

  }

}

@media only screen and (min-width: 1500px){
  .clickPin {
    left: 42.5%!important;
  }
}

@media only screen and (min-width: 1700px){
  .clickPin {
    left: 43.5%!important;
  }
}

@media only screen and (min-width: 1800px){
  .clickPin {
    left: 44%!important;
  }
}

@media only screen and (min-width: 1900px){
  .clickPin {
    left: 45%!important;
  }
}

@media only screen and (min-width: 2200px){
  .clickPin {
    left: 46%!important;
  }
}


.project-details-container{
  margin-left: 20px;
  position: relative;
  top: 80px;

  .panel-project{
    margin-top: 10px;
  }
  .panel-project-box{
    -webkit-box-shadow: 1px 1px 8px 2px rgba(153,150,153,1);
    -moz-box-shadow: 1px 1px 8px 2px rgba(153,150,153,1);
    box-shadow: 1px 1px 8px 2px rgba(153,150,153,1);
    border-radius: 2px;
  }

}

.panel-project-image{
  height: 100px;
  width: 100px;
}

.panel-project-image-container{
  padding-left: 0 !important;
  padding-right: 0 !important;

  .toggle{
    background-color: rgba(255, 255, 255, 0);
    color: #FFFFFF;
    width: 100px;
    height: 100px;
    position: absolute;
    text-align: center;
    span{
      display: none;
      width: 100px;
      height: 50px;
      float: left;
      margin-top: 15px;

      i{
        width: 100%;
      }
    }

    &:hover{
      background-color: rgba(0, 0, 0, 0.8);
      display: block;
      cursor: pointer;
    }
    &:hover span {
      display: block;
    }
  }
}

.panel-project-container:hover{
  cursor: pointer;
}

.project-details-container , .panel-project-container{
  .status-project{
    margin-top: 10px;
  }
}

.p-l-none{
  padding-left: 0 !important;
}

.p-r-none{
  padding-right: 0 !important;
}

.project-nav-bar{
  background-color: #FFFFFF;
  border-bottom: 1px solid #e4ecf2;
  display: block;
  float: left;
  width: 100%;
}

.project-nav-tabs {
  border-radius: 4px;
  font-weight: 300;
  background-color: #FFFFFF;
  margin: auto;
  width: 50%;

  a {
    border-left: 3px solid transparent;
    color: #636b6f;
  }

  a i {
    color: #a4aaae;
    width: 100%;
    position: relative;
  }

  li{
    float: left;
    text-align: center;
  }

  li:last-child a {
    border-bottom: 0;
  }

  li.active a {
    color: #3097D1;
    font-weight: bolder;
    border-bottom: 3px solid #3097D1;
  }

  li.active i{
    color: #3097D1;
  }

  li a:active,
  li a:hover,
  li a:link,
  li a:visited {
    background-color: white;
  }

}

.panel-table{
 margin-top: 20px;
}

.edit-button, #remove-team-member{
  cursor: pointer;
}

.panel-timesheet{

  ul, li{
    width:100%;
    padding: 0;
    list-style: none;
    display: block;
    float: left;
  }

  ul{
    margin-bottom: 20px;
  }

  li{
    margin: 10px 0;
    padding-left: 5px;
  }

  .timesheet-container{
    display: flex;
    flex-direction: row;
  }

  .timesheet-resume{
    border-top: 1px solid #d3e0e9;
    border-right: 1px solid #d3e0e9;
    -webkit-box-flex: 1;
    flex-grow: 1;
    margin: 0;
    padding: 15px 5px;
    flex-basis: 15%;
    font-size: 12px;
    min-height: 80px;
    text-align: center;
    &.last{
      border-right: none;
    }
    .info{
      width: 100%;
      clear: both;
      float: left;
    }
  }

  .timesheet-export {
    border-top: 1px solid #d3e0e9;
    -webkit-box-flex: 1;
    flex-grow: 1;
    margin: 0;
    padding: 5px;
    flex-basis: 15%;
    font-size: 12px;
    height: 30px;
    text-align: right;
    background-color: #f5f8fa;
    a, a:hover, a:active, a:visited{
      color: #000000;
      text-decoration: none;
      margin-right: 40px;
    }
  }

  .timesheet-data{
    border-top:1px solid #d3e0e9;
    border-right:1px solid #d3e0e9;
    flex-grow: 1;
    margin: 0 ;
    padding: 0 5px;
    flex-basis: 15%;
    font-size: 12px;
    min-height: 600px;

    &.last{
      border-right: none;
    }

    span{
      color: #000000;
    }

    .check-in, .lunch{
      border-left: 2px solid #3097D1;
    }

    .check-out{
      border-left: 2px solid #bf5329;
    }

    .total-hours{
      border-left: 2px solid #000000;
    }

    .incident{
      font-weight: bold;
    }

    .none{
      color: #636b6f;
      font-size: 100;
    }

    .left{
      float: left;
    }
    .right{
      float: right;
    }

    .incident-wrap{
      background-color: #D0EAFC;
      display: block;
      float: left;
      width: 100%;
      border-left: 2px solid #3097D1;
    }

    .injury{
      background-color: #FFDEDE;
      border-left: 2px solid #bf5329;
    }

    .type{
      border-bottom: 1px solid #d3e0e9;
      width: auto;
      padding-bottom: 5px;
    }

    .timesheet-label{
      color: #636B6F;
      width: 100%;
      text-align: center;
      font-size: 20px;
      height: 30px;
      margin-top: 100px;
    }

    .fa{
      width: 100%;
      text-align: center;
      margin: 50px auto;
    }

  }

  .pagination {
    margin: 0 !important;
    li {
      margin: 0 !important;
      width: auto;
    }
  }

  .month-header{
    width: 100%;
    background-color: #00b3ee;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    height: 40px;
    padding-top: 10px;

    .left{
      float: left;
    }

    .right{
      float: right;
    }

    span{
      margin: auto 10px;
    }


  }

  .day-header{
    height: 40px;
    width: 100%;
    display: flex;

    .date{
      color: #A0A0A0;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      flex-basis: 15%;
      flex-grow: 1;
      margin: auto;
    }
  }

}

.panel-team-timesheet{
  .spark-nav-profile-photo{
    margin-top: 0 !important;
  }
  &:hover{
    cursor: pointer;
  }

  .selected{
    background-color: #f5f8fa;
    .username{
      color: #3097D1;
    }
  }

  .user-company{
    font-size: 12px;
  }

  .total-hours{
    font-weight: bold;
    color: #00b3ee;
  }

  .team-header{
    text-align: center;
    margin-top: 10px;
  }

  .export-header{
    width: 100%;
    background-color: #00b3ee;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    height: 40px;
    padding-top: 10px;

    a, a:active, a:hover, a:focus{
      color: #FFFFFF;
      text-decoration: none;
    }
  }

}

.panel-activity-feed{

  .panel-input{

    height: 50px;

    .input-content{
      height: 100%;
      width: 100%;

      .team-member-image {
        margin: 10px;
        display: block;
        float: left;
      }

      #message{
        border: none;
        width: 80%;
        display: block;
        float: left;
        margin: 0 10px;
        height: 100%;
      }

      .btn{
        margin: 5px;
        float: left;
      }
    }
  }

  .message{
    padding: 10px;
    min-height: 65px;
    i{
      float: right;
      font-size: 20px;
      display: inline;
      height: 20px;
      width: 20px;
      margin: 0;
      z-index: 10;
    }

    .check-in{
      color: #77D559;
    }

    .check-out{
      color: #FF6565;
    }
  }

  .image-container {
    float: left;
    display: block;
  }

  .check-in-out{
    width: 70px;
    height: 50px;
  }

  .username{
    display: inline-block;
  }

  .message-text{
    margin-left: 75px;
  }

  .inline-message{
    display: inline-block;
    margin-left: 20px !important;
    margin-top: 10px;
  }

  .message-date{
    float: right;
  }

  .response{
    border-top: 1px solid #d3e0e9;
    padding-left: 75px;
  }

  .input-response{
    height: 30px;
    width: 100%;
    margin: 15px 30px;

    input{
      width: 80%;
      height: 100%;
      margin: auto;
    }

  }

  .panel-messages{
    background-color: #f5f8fa !important;
    border: none;
    .message-container{
      background-color: #FFFFFF;
      border: 1px solid #d3e0e9;
      margin-bottom: 50px;
    }
  }

  .file-upload-label{
    height: 35px;
    width: 35px;
    display: inline-block;
    margin-right: 15px !important;
    cursor: pointer;
    float: right;
    border-radius: 3px;
    background: #1497EF no-repeat center center url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4QIBFRoNB8GxfwAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAAJkSURBVEjHrZY9TFNRFMd/vS0PbSwMbUhsUhOGpkNbJ13ogHXThS4kEqITymBYGdSEkKgDI2KM2rWRpFOZ2AhDXRgtQ9OtTRgaiCktiC+0deC+8r5u7Yue7Z1z7//8z7nn4/kin34yROLAPDALJIEpqW8Ch8A+UARqKgCfwkEcWAMWGU0KwLqbI+FyeAmoeABHnq3IuxYJ2L5fAe/MiicJjWTYTzriJxMNUG/3qBx3qZx02a7qNNo946gGfJVpfO+WoiV5AIBYSPAhGyQTDShpt/Q+GwcXfPnx2256DuTNDuIyRM1g/TZzk0nNN1J+ykeXPNs941TvGyodSAE14w3WDPBYSHgCB8hEA6zev2FWaRITIdkPHnQrG/QEbshyetyezkUgLmSdDx50RpHzlt5nZe+c1nUaHLKZDdpV80I2EQCpiF8Jnit12K7q5EodpZM7IcGENfpZITsUgHTYrwRPStuLu+PMDXGStpJMClP7O9JjBt96eBX+QkJjeYiTlJXklAWx0e4RC1mb+9H0GKv3LBXCQkJTFkL9uvEGo6JpfFROuhbjpOZzgBvyeHrM1UnD6qAp5FS8cnDc5V/FRvJQyJELwLeqbu5Gh8zcDgwF/+wcGftCzvNBeBsHF0qA0twtpU1xtyjkDC+YWXw/uvScmpW9c3v0BfMsWpcDCoCnu2du4SqZ53Y6lK2kdIk5WDg14KVhPdX7vCn/IrfTsVeFI+cPim07OBKr5rYyHQsHYELzkY74SYX91Ns9Gu2eo6RN8lq1cMyL56Mxvj2ILpnn/7aT83JZFDyAF+Sd/Kh/Ff/tt+UPgDvnfkRxsOwAAAAASUVORK5CYII=");
  }

  .file-upload-form{
    display: none !important;
  }
}

.panel-media-feed {
  .toolbar {
    background-color: #fff;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    min-height: 60px;
    box-sizing: border-box;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-bottom: 1px solid #E4ECF2;

    .buttons {
      margin-left: 30px;

      input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }
    }

    .radio-buttons {
      display: inline-block;
      input {
        display: none;
      }
    }

    input:checked + label {
      background-color: #1497ef;
      box-shadow: none;
      color: white;
    }

    label {
      display: inline-block;
      width: 110px;
      background-color: #fff;
      color: #a0b1c2;
      font-size: 14px;
      text-align: center;
      padding: 8px 14px;
      transition: all 0.1s ease-in-out;
      font-family: Arial, Helvetica, sans-serif;
      border: 1px solid #a0b1c2;
      border-radius: 3px;
    }

    label:hover {
      cursor: pointer;
    }

    label[for='file-upload'] {
      height: 40px;
      width: 40px;
      display: inline-block;
      cursor: pointer;
      float: right;
      margin-left: 10px;
      border-radius: 3px;
      background: #1497EF no-repeat center center url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4QIBFRoNB8GxfwAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAAJkSURBVEjHrZY9TFNRFMd/vS0PbSwMbUhsUhOGpkNbJ13ogHXThS4kEqITymBYGdSEkKgDI2KM2rWRpFOZ2AhDXRgtQ9OtTRgaiCktiC+0deC+8r5u7Yue7Z1z7//8z7nn4/kin34yROLAPDALJIEpqW8Ch8A+UARqKgCfwkEcWAMWGU0KwLqbI+FyeAmoeABHnq3IuxYJ2L5fAe/MiicJjWTYTzriJxMNUG/3qBx3qZx02a7qNNo946gGfJVpfO+WoiV5AIBYSPAhGyQTDShpt/Q+GwcXfPnx2256DuTNDuIyRM1g/TZzk0nNN1J+ykeXPNs941TvGyodSAE14w3WDPBYSHgCB8hEA6zev2FWaRITIdkPHnQrG/QEbshyetyezkUgLmSdDx50RpHzlt5nZe+c1nUaHLKZDdpV80I2EQCpiF8Jnit12K7q5EodpZM7IcGENfpZITsUgHTYrwRPStuLu+PMDXGStpJMClP7O9JjBt96eBX+QkJjeYiTlJXklAWx0e4RC1mb+9H0GKv3LBXCQkJTFkL9uvEGo6JpfFROuhbjpOZzgBvyeHrM1UnD6qAp5FS8cnDc5V/FRvJQyJELwLeqbu5Gh8zcDgwF/+wcGftCzvNBeBsHF0qA0twtpU1xtyjkDC+YWXw/uvScmpW9c3v0BfMsWpcDCoCnu2du4SqZ53Y6lK2kdIk5WDg14KVhPdX7vCn/IrfTsVeFI+cPim07OBKr5rYyHQsHYELzkY74SYX91Ns9Gu2eo6RN8lq1cMyL56Mxvj2ILpnn/7aT83JZFDyAF+Sd/Kh/Ff/tt+UPgDvnfkRxsOwAAAAASUVORK5CYII=");
    }

    .filters {
      margin-right: 30px;
      select {
        background: #fff;
        color: #7c91ad;
        font-size: 14px;
        text-align: center;
        margin: 0 10px;
        padding: 8px 14px;
        font-family: Arial, Helvetica, sans-serif;
        border: 1px solid #a0b1c2;
        border-radius: 3px;
        cursor: pointer;
      }
    }

    .filters input[type=text] {
      background: #f4f4fb;
      color: #7c91ad;
      font-size: 14px;
      padding: 6px 14px;
      font-family: Arial, Helvetica, sans-serif;
      border: 1px solid #a0b1c2;
      border-radius: 3px;
    }

  }

  #tiles {
    padding: 30px;
    background-color: #F5F8FA;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-pack: center;
    justify-content: center; }
    .file-container {
      height: 240px;
      width: 240px;
      margin: 8px;
      display: block;
      border-radius: 4px;
      background-color: #fff;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
      text-decoration: none;
      color: #7c91ad;
      box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.2);

      a:hover{
        text-decoration: none;
      }

      .file-icon {
        display: block;
        background: no-repeat center center;
        background-size: cover;
        width: 128px;
        height: 128px;
        margin: auto;
        margin-top: 12px;
      }

      .file-preview {
        display: block;
        background: no-repeat center center;
        background-size: cover;
        width: 100%;
        height: 100%;
        margin: auto;
      }

      .file-details {
        padding: 0 12px;
        font-size: 13px;
        font-weight: bold;
        border-top: 1px solid #E4ECF2;
        display: block;
        margin-top: 10px;
        padding-top: 10px;
        overflow: hidden;
        text-overflow: clip;
        width:100%;
        word-wrap: break-word;

        p{
         margin: 0;
        }

        .date{
          font-size: 11px;
          color: #a0b1c2;
        }

      }
    }

   .file-container:hover {
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.5); }


  #overlay {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0.8;
    filter: alpha(opacity=80); }

  #loading {
    width: 50px;
    height: 57px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -28px 0 0 -25px;

  }
}

#modal-find-users{
  .modal-header{
    .btn-link {
      color: #FFFFFF;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .close{
    font-size: 13px;
    font-weight: 600;
    margin-top: 10px;
    float: left;
    opacity: 1;
    text-shadow: none;
    &:hover{
      opacity: 0.5;
    }
  }

  .next{
    float: right;
    &:hover{
      opacity: 0.5;
    }
  }

  .modal-body{
      min-height: 500px;
    .loading{
      padding: 50px 0;
      text-align: center;
    }
  }

  .panel-result{
    margin-top: 20px;
  }

  .multiselect__tag{
    padding: 2px 10px;
    margin: 0 10px;
    background-color: #00b3ee;
    color: #FFFFFF;
    border-radius: 10px ;
    font-weight: bold;
  }

  .multiselect__single{
    display: none !important;
  }

  .multiselect__content{
    display: block !important;
    padding: 0;
  }

  .multiselect__element{
    list-style: none !important;
    padding: 10px 0;
    border-bottom: 1px solid #ccd0d2;
    cursor: pointer;
    i{
      float: right;
      color: #FFFFFF;
      margin-right: 20px;
      margin-top: 8px;
    }
  }

  .multiselect__tags{
    padding: 5px 0;
  }

  .multiselect__option--selected{
    i{
      color: #00b3ee;
    }
  }

  .team-member-image{
    margin-right: 10px;
  }
}

i.fa {
  margin-right: 10px;
}

#modal-gallery{

  .modal-body{
    height: 600px;
  }

  .modal-dialog{
    margin-top: 200px;
  }

  #carousel-gallery{
    height: 600px;

    .carousel-control{
      height: 100px;
      margin: auto;
    }

    .carousel-inner{
      .item{
        min-height: 600px;
        max-height: 600px;
        background-color: rgba(0,0,0,0.9);

        img{
          margin: auto !important;
          height: 500px;
          padding: 40px;
        }

        .carousel-caption{
          padding-bottom: 0;
          padding-top: 10px;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: #f5f8fa;
          height: 110px;
          width: 100%;
          p{
            margin-bottom: 5px;
            text-shadow: none;
          }
        }
      }
      .edit-link{
        margin-left: 10px;
        i{
          margin-right: 5px;
        }
      }
    }
  }
}

.subscription-notification{
  a, a:hover, a:visited, a:active, a:focus{
    text-decoration: none;
  }
}

@media only screen and (max-width: 768px) {
  #download-apps {
    display: block;
    background-color: #1497EF;
    .modal-dialog{
      top: 20px;
    }
    img{
      width: 200px;
      margin: 30px;
    }
  }

  .project-container{
    height: 320px;

    .panel-project{
      width: 100%;
    }
  }

  .project-nav-tabs{
    width: 100%;
    margin-bottom: 20px;
  }

  .vue-image-crop-upload .vicp-wrap{
    width: 90%;
    height: 90%;
  }
}

@media only screen and (max-width: 1250px) {
  .panel-activity-feed .panel-input .input-content #message{
    width: 75%;
  }
}

@media only screen and (max-width: 1024px) {
  body#guests{
    background-color: #1497EF !important;
  }

  .panel-activity-feed .panel-input .input-content #message{
    width: 60%;
  }
}
