.wizard > .content{
  background-color: #1497EF;
}
#registration-form, #login-form{
  background-color: #1497EF;
  h2{
    color: white;
    font-weight: bolder;
  }
  a{
    color: white;
  }
  .help-block{
      display: block;
      margin-top: 5px;
      margin-bottom: 0;
      color: #dc0a06;
      font-weight: normal;
  }
  .panel-default{
   border:none;
  }
  .form-group{
    background-color: #1497EF;
    color: white;
    font-weight: bolder;
    .field{
      float: none;
      margin: auto;
    }
    .field-terms{
      margin: 20px auto;
      text-align: center;
    }
  }
  .form-control{
    background-color: #43ABF2;
    color: white;
    font-weight: bolder;
    border:none;
    :-moz-placeholder, ::-moz-placeholder, :-ms-input-placeholder, ::-webkit-input-placeholder {
      color: white !important;
      opacity: 0.5 !important;
    }
  }
  .panel-body, .panel-heading{
    background-color: #1497EF;
    color: white;
    font-weight: bold;
    border: none;
  }
  .panel-heading{
    .header-text{
       width: 85%;
      text-align: center;
    }
  }
  .control-label{
    font-weight: bolder;
  }
  button{
    border: 2px solid #ffffff;
    background-color: #1497EF;
    color: white;
  }

  .white-panel-body{
    background-color: #ffffff;
    color: #A2B2C3;
    text-align: center;
    padding: 20px;

    h3, h5{
      color: #1497EF;
      font-weight: bold;
    }
    span{
      color: #000000;
      font-weight: bold;
    }

    .purchase-button{
      background-color: #1497EF;
      color: #ffffff;
      padding: 10px;
      border-radius: 5px;
      font-weight:bold;
      &:hover{
        background-color: #fff;
        color: #37A7F2;
        border: 1px solid #37A7F2;
      }
      &.apply-coupon{
        padding: 5px;
      }
    }

    .feature-list{
      width: 50%;
      margin: 30px 0 30px 40%;
      .feature{
        width: 100%;
        text-align: left;
        margin: 10px 0;
        i{
          margin-right: 15px;
          color: #1497EF;
        }
      }
    }

    .form-group{
      background-color: #FFFFFF;
      color: #1497EF;

      input{
        background-color: #F4F4Fb;
        color: #A2B2C3;
        font-weight: normal;
      }
    }
  }

  .header-button {
    width: 100px;
    height: 30px;
    float: left;
    border: none !important;
  }

  .panel-purchase-account{
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
  }

  .terms-of-service{
    color: #A2B2C3;
    a{
      color: #A2B2C3;
      font-weight: bold;
      &:hover{
        text-decoration: none;
      }
    }
  }

  .right {
    float: right !important;
  }

  .show-button{
    background-color: #43ABF2;
    color: white;
    border: none;
    padding: 8px;
  }

  #termsModal{
    #closeTermsModal button.close{
      background-color: #FFFFFF;
      color: #000000;
    }

    .modal-dialog{
      height: 95%;

      .modal-content{
        height: 100%;

        .modal-body{
          height: 100%;
          overflow: hidden;
          padding: 0 10px;
          width: 100%;

          .embed-responsive{
            height: 100%;
            overflow-y: scroll;
            padding: 0 !important;
            width: 100%;
          }

          .panel-body{
            background-color: #FFFFFF;
            color:#636B6F;
            font-weight: 300;

            h2{
              color: #636b6f;
            }
          }

          .panel-heading{
            background-color: #f5f5f5;
            color: #333333;
            border-color: #D3E0E9;

          }
        }
      }
    }

  }
}


@media only screen and (max-width: 768px) {
  #registration-form {

    .last-name {
      right: 0 !important;
      position: relative !important;
    }

    .checkbox{
      margin-left: 20px;
    }
  }

  .vue-image-crop-upload .vicp-wrap{
    width: 90% !important;
    height: 90% !important;

    .vicp-img-container{
      margin: auto;
    }

    .vicp-crop-left, .vicp-crop-right{
      width: 100%;
      height: 50%;
    }

    .vicp-preview{
      width: 100% !important;
      margin: auto !important;
      height: 100% !important;

      .vicp-preview-item{
        margin-bottom: 20px;
        span{
          display: none !important;
        }
      }
    }

    .vicp-range{
      margin: 30px auto !important;
    }

    .vicp-preview-item{
      width: 45% !important;
    }
  }
}

@media only screen and (max-width: 991px){
  .input-expiration-date{
    width: 40% !important;
    float: left;
  }
}

