/* Main body section */
.side-body {
  margin-left: 230px;
}

/* Main menu */
.side-menu {
  position: fixed;
  width: 210px;
  height: 100%;
  background-color: #2E3747;
}

.side-menu .navbar {
  border: none;
  background-color: #2E3747;
}

.side-menu .navbar-header {
  width: 100%;
  border-bottom: 1px solid #e7e7e7;
}

.side-menu .navbar-nav .active a {
  margin-right: -1px;
  border-right: 5px solid #e7e7e7;
}

.side-menu .navbar-nav li {
  display: block;
  width: 100%;
}

.side-menu .navbar-nav li a {
  padding: 15px;
  color: #fff;
  justify-content: flex-start;

  i {
    margin-right: 20px;
  }

  &:hover {
    color: #fff;
    background-color: darken(#2E3747, 5%);
  }

  &.active {
    background-color: darken(#2E3747, 10%);
  }

}

.side-menu #dropdown {
  border: 0;
  margin-bottom: 0;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
  color: #fff;
}

.side-menu #dropdown .caret {
  float: right;
  margin: 0 20px;
}

.side-menu #dropdown .indicator {
  float: right;
}


.submenu-item {
  height: 40px!important;
  margin-left: 50px;
}

/* small screen */
@media (max-width: 768px) {
  .side-menu {
    position: relative;
    width: 100%;
    height: 0;
    border-right: 0;
    border-bottom: 1px solid #e7e7e7;
  }
  .side-menu .brand-name-wrapper .navbar-brand {
    display: inline-block;
  }

  @keyframes slidein {
    0% {
      left: -300px;
    }
    100% {
      left: 10px;
    }
  }

  @keyframes slideout {
    0% {
      left: 0;
    }
    100% {
      left: -300px;
    }
  }


  /* Slide side menu*/

  .side-menu-container > .navbar-nav.slide-in {
    animation: slidein 300ms forwards;
    transform-style: preserve-3d;
  }
  .side-menu-container > .navbar-nav {
    position: fixed;
    left: -300px;
    width: 300px;
    top: 43px;
    height: 100%;
    //border-right: 1px solid #e7e7e7;
    background-color: #2E3747;
    animation: slideout 300ms forwards;
    transform-style: preserve-3d;
  }

  @keyframes bodyslidein {
    0% {
      left: 0;
    }
    100% {
      left: 300px;
    }
  }

  @keyframes bodyslideout {
    0% {
      left: 300px;
    }
    100% {
      left: 0;
    }
  }

  /* Slide side body*/
  .side-body {
    margin-left: 5px;
    margin-top: 70px;
    position: relative;
    animation: bodyslideout 300ms forwards;
    transform-style: preserve-3d;
  }
  .body-slide-in {
    animation: bodyslidein 300ms forwards;
    transform-style: preserve-3d;
  }

  /* Hamburger */
  .navbar-toggle {
    border: 0;
    float: left;
    padding: 18px;
    margin: 0;
    border-radius: 0;
  }

  .navbar-header {
    position: fixed;
    z-index: 3;
  }

  /* Dropdown tweek */
  #dropdown .panel-body .navbar-nav {
    margin: 0;
  }
}

