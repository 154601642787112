.pac-container {
    z-index: 1051 !important; /** to show the autocomplete options in a modal */
}
.project-image-thumbnail{
    min-height: 68px;
    max-height: 68px;
}

.project-image-modal-panel{
    width: 13%;
    margin-right: 10px;
    display: inline-block;
}
